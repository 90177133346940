@charset "UTF-8";
h1, h2, h3, h4, h5, h6,
.headline,
.subline,
.title {
  display: block;
  margin-top: 1.2em;
  margin-bottom: 1.2em;
  font-size: 1.2em;
  line-height: 1.3; }

.form-element-submit, .hero-caption-button {
  appearance: none;
  vertical-align: middle;
  white-space: nowrap;
  display: inline-block;
  height: 2.4em;
  margin: 0;
  padding: 0;
  padding-right: 2em;
  padding-left: 2em;
  font-size: .8em;
  line-height: 2.4em;
  font-family: "Raleway";
  text-transform: uppercase;
  letter-spacing: 1px;
  color: inherit;
  background: transparent;
  border: none;
  border-radius: 2px;
  box-shadow: 0 0 0 1px currentcolor; }

/* ==========================================================================
   Normalize.scss settings
   ========================================================================== */
/**
 * Includes legacy browser support IE6/7
 *
 * Set to false if you want to drop support for IE6 and IE7
 */
/* Base
   ========================================================================== */
/**
 * 1. Set default font family to sans-serif.
 * 2. Prevent iOS and IE text size adjust after device orientation change,
 *    without disabling user zoom.
 * 3. Corrects text resizing oddly in IE 6/7 when body `font-size` is set using
 *  `em` units.
 */
html {
  font-family: sans-serif;
  /* 1 */
  -ms-text-size-adjust: 100%;
  /* 2 */
  -webkit-text-size-adjust: 100%;
  /* 2 */ }

/**
 * Remove default margin.
 */
body {
  margin: 0; }

/* HTML5 display definitions
   ========================================================================== */
/**
 * Correct `block` display not defined for any HTML5 element in IE 8/9.
 * Correct `block` display not defined for `details` or `summary` in IE 10/11
 * and Firefox.
 * Correct `block` display not defined for `main` in IE 11.
 */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section,
summary {
  display: block; }

/**
 * 1. Correct `inline-block` display not defined in IE 6/7/8/9 and Firefox 3.
 * 2. Normalize vertical alignment of `progress` in Chrome, Firefox, and Opera.
 */
audio,
canvas,
progress,
video {
  display: inline-block;
  /* 1 */
  vertical-align: baseline;
  /* 2 */ }

/**
 * Prevents modern browsers from displaying `audio` without controls.
 * Remove excess height in iOS 5 devices.
 */
audio:not([controls]) {
  display: none;
  height: 0; }

/**
 * Address `[hidden]` styling not present in IE 8/9/10.
 * Hide the `template` element in IE 8/9/10/11, Safari, and Firefox < 22.
 */
[hidden],
template {
  display: none; }

/* Links
   ========================================================================== */
/**
 * Remove the gray background color from active links in IE 10.
 */
a {
  background-color: transparent; }

/**
 * Improve readability of focused elements when they are also in an
 * active/hover state.
 */
a:active, a:hover {
  outline: 0; }

/* Text-level semantics
   ========================================================================== */
/**
 * Address styling not present in IE 8/9/10/11, Safari, and Chrome.
 */
abbr[title] {
  border-bottom: 1px dotted; }

/**
 * Address style set to `bolder` in Firefox 4+, Safari, and Chrome.
 */
b,
strong {
  font-weight: bold; }

/**
 * Address styling not present in Safari and Chrome.
 */
dfn {
  font-style: italic; }

/**
 * Address variable `h1` font-size and margin within `section` and `article`
 * contexts in Firefox 4+, Safari, and Chrome.
 */
h1 {
  font-size: 2em;
  margin: 0.67em 0; }

/**
 * Addresses styling not present in IE 8/9.
 */
mark {
  background: #ff0;
  color: #000; }

/**
 * Address inconsistent and variable font size in all browsers.
 */
small {
  font-size: 80%; }

/**
 * Prevent `sub` and `sup` affecting `line-height` in all browsers.
 */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sup {
  top: -0.5em; }

sub {
  bottom: -0.25em; }

/* Embedded content
   ========================================================================== */
/**
 * 1. Remove border when inside `a` element in IE 8/9/10.
 * 2. Improves image quality when scaled in IE 7.
 */
img {
  border: 0; }

/**
 * Correct overflow not hidden in IE 9/10/11.
 */
svg:not(:root) {
  overflow: hidden; }

/* Grouping content
   ========================================================================== */
/**
 * Address margin not present in IE 8/9 and Safari.
 */
figure {
  margin: 1em 40px; }

/**
 * Address differences between Firefox and other browsers.
 */
hr {
  box-sizing: content-box;
  height: 0; }

/**
 * Contain overflow in all browsers.
 */
pre {
  overflow: auto; }

/**
 * Address odd `em`-unit font size rendering in all browsers.
 * Correct font family set oddly in IE 6, Safari 4/5, and Chrome.
 */
code,
kbd,
pre,
samp {
  font-family: monospace, monospace;
  font-size: 1em; }

/* Forms
   ========================================================================== */
/**
 * Known limitation: by default, Chrome and Safari on OS X allow very limited
 * styling of `select`, unless a `border` property is set.
 */
/**
 * 1. Correct color not being inherited.
 *  Known issue: affects color of disabled elements.
 * 2. Correct font properties not being inherited.
 * 3. Address margins set differently in Firefox 4+, Safari, and Chrome.
 * 4. Improves appearance and consistency in all browsers.
 */
button,
input,
optgroup,
select,
textarea {
  color: inherit;
  /* 1 */
  font: inherit;
  /* 2 */
  margin: 0;
  /* 3 */ }

/**
 * Address `overflow` set to `hidden` in IE 8/9/10/11.
 */
button {
  overflow: visible; }

/**
 * Address inconsistent `text-transform` inheritance for `button` and `select`.
 * All other form control elements do not inherit `text-transform` values.
 * Correct `button` style inheritance in Firefox, IE 8/9/10/11, and Opera.
 * Correct `select` style inheritance in Firefox.
 */
button,
select {
  text-transform: none; }

/**
 * 1. Avoid the WebKit bug in Android 4.0.* where (2) destroys native `audio`
 *  and `video` controls.
 * 2. Correct inability to style clickable `input` types in iOS.
 * 3. Improve usability and consistency of cursor style between image-type
 *  `input` and others.
 * 4. Removes inner spacing in IE 7 without affecting normal text inputs.
 *  Known issue: inner spacing remains in IE 6.
 */
button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  /* 2 */
  cursor: pointer;
  /* 3 */ }

/**
 * Re-set default cursor for disabled elements.
 */
button[disabled],
html input[disabled] {
  cursor: default; }

/**
 * Remove inner padding and border in Firefox 4+.
 */
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0; }

/**
 * Address Firefox 4+ setting `line-height` on `input` using `!important` in
 * the UA stylesheet.
 */
input {
  line-height: normal; }

/**
 * 1. Address box sizing set to `content-box` in IE 8/9/10.
 * 2. Remove excess padding in IE 8/9/10.
 *  Known issue: excess padding remains in IE 6.
 */
input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */ }

/**
 * Fix the cursor style for Chrome's increment/decrement buttons. For certain
 * `font-size` values of the `input`, it causes the cursor style of the
 * decrement button to change from `default` to `text`.
 */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  height: auto; }

/**
 * 1. Address `appearance` set to `searchfield` in Safari and Chrome.
 * 2. Address `box-sizing` set to `border-box` in Safari and Chrome.
 */
input[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  box-sizing: content-box;
  /* 2 */ }

/**
 * Remove inner padding and search cancel button in Safari and Chrome on OS X.
 * Safari (but not Chrome) clips the cancel button when the search input has
 * padding (and `textfield` appearance).
 */
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

/**
 * Define consistent border, margin, and padding.
 */
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em; }

/**
 * 1. Correct `color` not being inherited in IE 8/9/10/11.
 * 2. Remove padding so people aren't caught out if they zero out fieldsets.
 * 3. Corrects text not wrapping in Firefox 3.
 * 4. Corrects alignment displayed oddly in IE 6/7.
 */
legend {
  border: 0;
  /* 1 */
  padding: 0;
  /* 2 */ }

/**
 * Remove default vertical scrollbar in IE 8/9/10/11.
 */
textarea {
  overflow: auto; }

/**
 * Don't inherit the `font-weight` (applied by a rule above).
 * NOTE: the default cannot safely be changed in Chrome and Safari on OS X.
 */
optgroup {
  font-weight: bold; }

/* Tables
   ========================================================================== */
/**
 * Remove most spacing between table cells.
 */
table {
  border-collapse: collapse;
  border-spacing: 0; }

td,
th {
  padding: 0; }

* {
  box-sizing: border-box !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }
  *::before, *::after {
    box-sizing: inherit; }

html {
  font-size: 100%;
  font-family: Helvetica, Arial, sans-serif;
  font-variant-ligatures: common-ligatures;
  font-feature-settings: "liga", "clig";
  -webkit-text-size-adjust: 100%; }

body {
  font-weight: 400;
  font-size: 100%;
  line-height: 1.5;
  font-family: "Raleway";
  color: #4d4d4d;
  background-color: #333333; }

@media (min-width: 46.875em) {
  body {
    font-size: 112.5%; } }
@media (min-width: 80em) {
  body {
    font-size: 125%; } }
header {
  text-align: center; }

fieldset, figure, figcaption, blockquote {
  margin: 0;
  padding: 0; }

p, ul, ol, figure, blockquote {
  margin-top: 1.2em;
  margin-bottom: 1.2em; }

a,
a:link,
a:visited,
a:hover,
a:active {
  text-decoration: none; }

a {
  color: #db0a5b; }

.main {
  padding-top: 5rem;
  background-color: white; }

section {
  padding: 2rem; }
  section::before, section::after {
    content: "";
    clear: both;
    display: table;
    width: 100%; }

fieldset {
  border: none; }

figure {
  margin-top: 2em;
  margin-bottom: 2em;
  text-align: center; }

figcaption {
  padding-right: 1em;
  padding-left: 1em;
  font-size: 0.8em;
  line-height: 1.3; }

address {
  font-style: normal; }

img {
  max-width: 100%;
  height: auto; }

.portrait {
  display: block;
  margin-right: auto;
  margin-left: auto; }

.wrap {
  position: relative;
  max-width: 1280px;
  margin-right: auto;
  margin-left: auto; }

.grid {
  max-width: 1280px;
  margin-left: auto;
  margin-right: auto; }
  .grid:after {
    content: " ";
    display: block;
    clear: both; }

.title {
  width: 100%;
  display: flex;
  margin-top: 0;
  justify-content: center;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: #4ecdc4; }

.title::before,
.title::after {
  content: "";
  flex-grow: 1;
  align-self: center;
  height: .1em;
  background-color: #4ecdc4;
  background-clip: content-box; }

.title::before {
  margin-right: 1rem; }

.title::after {
  margin-left: 1rem; }

.form-error,
.form-success {
  display: block;
  padding: .3em;
  font-weight: 700;
  border: 1px solid; }

.form-error {
  margin-top: .3em;
  font-size: 0.8em;
  color: #db0a5b;
  background-color: rgba(255, 0, 51, 0.1); }

.form-success {
  margin-bottom: 1em;
  color: #4ecdc4;
  background-color: rgba(0, 102, 51, 0.1); }

[class*="form-element-"] {
  appearance: none;
  vertical-align: middle;
  display: inline-block;
  width: 100%;
  height: 2.4em;
  margin: 0;
  padding: 0;
  padding-right: .8em;
  padding-left: .8em;
  font-size: 1em;
  line-height: 2.4em;
  color: #4ecdc4;
  background: transparent;
  background-color: #f2f2f2;
  border: none;
  border: 1px solid transparent;
  border-radius: .1em;
  box-shadow: 0 0 0 1px #9ee3df;
  transition: box-shadow 150ms; }

[class*="form-element-"]:focus {
  outline: none;
  box-shadow: 0 0 0 1px #f75493; }

[class*="form-element-"] + [class*="form-element-"] {
  margin-top: 1.2em; }

.form-element-label,
.form-element-submit {
  box-shadow: none; }

.form-element-label {
  height: auto;
  margin: 0;
  padding: 0;
  font-size: .8em;
  line-height: 1.3;
  text-transform: uppercase;
  letter-spacing: 1px;
  line-height: 1.5;
  color: inherit;
  background-color: transparent;
  box-shadow: none; }

.form-element-label > [class*="form-element-"] {
  margin-top: .3em; }

.form-element-textarea {
  height: auto;
  padding-top: .5em;
  padding-bottom: .5em;
  line-height: 1.5; }

.form-element-submit {
  width: auto;
  font-size: .8em;
  color: white;
  background-color: #4ecdc4;
  box-shadow: none; }

.form-element-submit:focus {
  box-shadow: none; }

.grid-items {
  max-width: 1280px;
  margin-left: auto;
  margin-right: auto;
  overflow: hidden; }
  .grid-items:after {
    content: " ";
    display: block;
    clear: both; }

@media (min-width: 62.5em) {
  .grid-items-separated-50 {
    background-image: linear-gradient(90deg, transparent, rgba(0, 0, 0, 0.1), transparent);
    background-position: center;
    background-repeat: repeat-y;
    background-size: 1px auto; }

  .grid-item-50,
  .grid-item-50-center {
    width: 48.07692%;
    float: left;
    margin-left: 3.84615%; }

  .grid-item-50:first-child {
    margin-left: 0; }

  .grid-item-50-center {
    margin-left: 25.96154%; } }
.shortcuts {
  display: inline; }

.shortcut {
  overflow: hidden;
  text-indent: 150%; }

.shortcut[href="#navigation"] {
  z-index: 500;
  position: fixed;
  top: 1.875rem;
  right: 2rem;
  width: 1.25rem;
  height: 1.25rem;
  background-image: url("../images/icon-navigation.svg");
  background-size: cover;
  transition: background-image 200ms, transform 150ms 50ms; }

.shortcut[href="#navigation"].is-active {
  background-image: url("../images/icon-close.svg");
  transform: rotate(90deg); }

.shortcut[href="#navigation"]::before {
  content: "Navigation";
  line-height: 1em; }

@media (min-width: 46.875em) {
  .shortcut[href="#navigation"] {
    width: 1em;
    height: 1em; } }
@media (min-width: 62.5em) {
  .shortcut[href="#navigation"] {
    overflow: hidden;
    position: absolute;
    top: auto;
    left: -10000px;
    width: 1px;
    height: 1px; } }
.teasers {
  background-color: rgba(78, 205, 196, 0.1); }

.teaser-items {
  margin: 0;
  padding: 0;
  list-style: none;
  margin-right: auto;
  margin-left: auto;
  text-align: center; }

.teaser-item {
  overflow: hidden;
  position: relative;
  font-size: 1em;
  line-height: 1.3;
  color: #db0a5b;
  background-color: #db0a5b; }

.teaser-item-label {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  padding-right: 1em;
  padding-left: 1em;
  transform: translatey(-50%);
  transition: opacity 450ms; }

.teaser-item-link:hover .teaser-item-label {
  opacity: 0; }

.teaser-item-link {
  position: relative;
  display: block;
  color: white; }

.teaser-item-link::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  background-color: rgba(219, 10, 91, 0.9);
  transition: width 450ms; }

.teaser-item-link:hover::before {
  width: 0; }

.teaser-item-image {
  display: block;
  width: 100%; }

@media (max-width: 37.49em) {
  .teaser-item {
    display: inline-block;
    max-width: 12em; }

  .teaser-item + .teaser-item {
    margin-top: 1em; } }
@media (min-width: 37.5em) {
  .teaser-item {
    width: 30.76923%;
    float: left;
    margin-left: 3.84615%; }

  .teaser-item:first-child {
    margin-left: 0; } }
.post + .post {
  margin-top: 2em;
  padding-top: 2em;
  border-top: 1px solid #e6e6e6; }

.post-header {
  text-align: left; }

.post-title {
  margin-top: 0; }

.highlight {
  padding-right: 1em;
  padding-left: 1em;
  background-color: rgba(78, 205, 196, 0.1);
  border: 1px solid transparent; }

.highlight-title {
  color: #db0a5b; }

.paging {
  margin-top: 2em;
  margin-bottom: 2em;
  padding-top: 2em;
  text-align: center;
  border-top: 1px solid #e6e6e6; }

.quote {
  padding-right: 2em;
  padding-left: 2em;
  font-style: italic;
  text-align: center; }

.quote-author {
  display: block;
  margin-top: .8em;
  font-style: normal;
  font-size: .8em;
  text-transform: uppercase; }

.quote-author::before {
  content: "—"; }

[role="navigation"] .navigation-items {
  margin: 0;
  padding: 0;
  list-style: none; }

@media (max-width: 62.49em) {
  [role="navigation"] {
    z-index: 400;
    position: fixed;
    top: 0;
    right: 0;
    min-width: 20em;
    padding-top: 5rem;
    background-color: rgba(0, 0, 0, 0.8);
    transform: translatey(-150%);
    transition: transform 220ms cubic-bezier(0.785, 0.135, 0.15, 0.86); }

  [role="navigation"]:target,
  html.navigation-is-active [role="navigation"] {
    transform: none; }

  [role="navigation"] .navigation-item-link {
    white-space: nowrap;
    display: block;
    padding-right: 2rem;
    padding-left: 2rem;
    font-size: 1.2em;
    line-height: 3em;
    color: white; }

  [role="navigation"] .navigation-item + .navigation-item .navigation-item-link {
    border-top: 0.3rem solid rgba(255, 255, 255, 0.3); } }
@media (min-width: 62.5em) {
  [role="navigation"] {
    z-index: 500;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%; }

  [role="navigation"] .navigation-items {
    position: absolute;
    top: 0;
    right: 2rem;
    height: 5rem;
    line-height: 5rem; }

  [role="navigation"] .navigation-item {
    display: inline-block;
    line-height: 1.5; }

  [role="navigation"] .navigation-item + .navigation-item {
    margin-left: 2rem; }

  [role="navigation"] .navigation-item-link {
    position: relative;
    color: #4ecdc4; }

  [role="navigation"] .navigation-item-link::after {
    content: "";
    position: absolute;
    bottom: -0.3em;
    left: 50%;
    width: 0;
    height: .1em;
    background-color: #d9d9d9;
    transform: translatex(-50%);
    transition: width 300ms cubic-bezier(0.175, 0.885, 0.32, 1.275); }

  [role="navigation"] .navigation-item-link:hover::after {
    width: 100%; }

  [role="navigation"] .navigation-item.active .navigation-item-link {
    color: #db0a5b; } }
[role="banner"] {
  z-index: 400;
  position: fixed;
  top: 0;
  width: 100%;
  height: 5rem;
  padding-left: 2rem;
  line-height: 5rem;
  text-align: left;
  background-color: white;
  box-shadow: 0 0 0 0.3rem rgba(0, 0, 0, 0.1); }

[role="banner"] .banner-branding {
  vertical-align: middle;
  display: inline-block;
  line-height: 1.3; }

[role="banner"] .banner-branding-title {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 1.5em;
  font-family: "Lobster Two";
  color: #db0a5b; }

[role="banner"] .banner-branding-tagline {
  display: block;
  font-size: .8em;
  color: #4ecdc4; }

[role="banner"] .banner-branding-link {
  font: inherit;
  color: inherit; }

@media (min-width: 37.5em) {
  [role="banner"] .banner-branding-tagline {
    text-align: center; } }
[role="contentinfo"] {
  padding: 2rem;
  font-size: .8em;
  text-align: center;
  color: white; }

[role="contentinfo"] a {
  color: inherit; }

.contact {
  background-image: url("../images/mandala-4.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain; }

.hero {
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  max-height: 20em;
  text-align: center;
  color: white;
  background-color: #db0a5b; }

.hero::before,
.hero::after {
  content: ""; }

.hero::before {
  content: "";
  display: block;
  height: 0;
  padding-bottom: 100%; }

.hero::after {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50%;
  width: 100%;
  max-width: 1280px;
  background-image: url("../images/mandala-3.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 80%;
  transform: translatex(-50%); }

.hero-caption {
  z-index: +1;
  width: 100%;
  padding-right: 2rem;
  padding-left: 2rem; }

.hero-caption-button {
  margin-top: 1em; }

.hero-image {
  overflow: hidden;
  position: absolute;
  top: auto;
  left: -10000px;
  width: 1px;
  height: 1px; }

.hero-caption-headline {
  margin-top: 0;
  margin-bottom: 0; }

.hero-caption-subline {
  display: block;
  margin-right: auto;
  margin-left: auto;
  max-width: 30em;
  font-weight: 300; }

@media (min-width: 62.5em) {
  .hero {
    max-height: 25em; }

  .hero-caption-headline {
    font-size: 2.25em; }

  .hero-caption-subline {
    font-size: 1.5em; } }


